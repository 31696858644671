import React, { useLayoutEffect, useRef } from "react";
import $ from "jquery";

const Select2Field = ({ name, label, options, value, webChange }) => {
  const selectRef = useRef();

  useLayoutEffect(() => {
    const $select = $(selectRef.current);

    $select.select2();

    $select.on("change", (event) => {
      webChange(event);
      const selectedValue = $(event.target).val();
    });

    return () => {
      $select.select2("destroy");
      $select.off("change");
    };
  }, []);

  return (
    <>
      <select
        ref={selectRef}
        name={name}
        className="form-select register-select2"
        value={value || ""}
        id={name}
      >
        <option value="" disabled={true} selected={true}>
          Select {label}
        </option>
        {options.map((opt) => (
          <option key={opt.url} value={opt.url}>
            {opt.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select2Field;
